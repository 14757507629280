import React from "react";

const strokeStyle = { vectorEffect: "non-scaling-stroke" };

const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 649 649">
    <defs />
    <path
      fill="#0BDAC5"
      d="M300.363 647.275c-34-2.4-71-11.4-101.5-24.8-11.6-5.1-27.4-12.9-34.2-17l-3.8-2.3-.2-114.1c-.4-182.1-.5-178.6 3.8-201.2 5.1-26.8 20-54.5 41.1-76.5 22.6-23.6 49.1-39.1 80.3-47.1 12.4-3.2 37.4-6.3 41.8-5.3 7.5 1.8 11.9 8.7 10.9 17.2-.4 3.7-1.2 5.3-4.1 7.9-4 3.7-4.1 3.7-19.3 5.2-48.7 4.9-90.7 33.6-112.1 76.6-4.2 8.6-6.9 16.2-10.4 29.5-2.1 8.3-2.2 10.1-2.5 76-.2 37.1 0 100.4.4 140.5l.8 73 7.5 3.7c26.3 12.9 59.8 22.9 92 27.4 15.7 2.3 57.6 2.6 72 .6 59-8.2 106.8-29.7 152.5-68.6 50.9-43.3 87.6-107.1 100-173.6 6.2-33.4 6-74.1-.4-107-7.1-35.8-23.1-74.5-42.8-103.5l-3.4-5 .3 90.5c.2 69.5 0 92.5-1 99-4 26.5-9.2 46.3-17.6 67-26.8 65.1-81.9 116.9-148.6 139.4-20.3 6.8-38.2 10.5-61.4 12.7-13.7 1.2-19.3.7-23.2-2.3-7.2-5.8-7.1-18.8.3-24.2 2.1-1.6 5.2-2.1 14.9-2.8 45.9-2.8 86.4-17.6 121.9-44.3 10.7-8.1 28-24.9 36.9-35.8 21.8-27.1 37.3-59.8 43.9-92.9 5-24.8 5-25.6 4.3-156.6-.4-67.3-.9-123.6-1.3-125.3-.4-2-.2-2.9.7-2.9 2.2 0 33 31.9 41.6 43 37.7 48.9 57.5 96.5 66.1 158.9 2.4 17.7 2.4 64.4-.1 82.1-5.3 38.7-15.3 72.2-31.3 104.7-24.7 50.5-60.4 90.7-108.8 122.8-49.5 32.8-103.5 51.1-164 55.5-15.7 1.1-24.5 1.1-42-.1z"
    />
    <path
      fill="#0BDAC5"
      d="M97 554.5c-7.9-7.6-14.5-15.1-15-16-.5-.9-5.5-6.2-10.5-12.5C24.2 466.8-1.337 388.575 1.163 315.875 4.58 216.5 34.6 155.275 92 96.175c53-54.6 115.5-84.399 201.363-94.9 13.9-1.7 55.9-1.7 68.5 0 34.6 4.5 62.7 12.1 92 24.8 10.9 4.7 30.2 14.4 33.3 16.7 1.7 1.3 1.8 8.3 2.4 141.7.6 148 .5 154.2-3.9 175.3-3.9 19.3-10.4 35-21.6 52.1-23.1 35.4-58.2 60.8-98.7 71-21.9 5.6-41.4 7.1-47.2 3.5-8.5-5.2-8.7-19.8-.3-24.9 2-1.2 6.9-2.1 15-2.9 38.3-3.8 69.2-19.3 95-47.7 15.1-16.8 25.9-39.6 31-65.5 1.5-7.4 1.4-281.9-.1-283.6-1.3-1.6-23.7-11.6-35.6-16-72.5-26.2-147-22.9-218.5 9.7-92.8 42.4-158.6 131.3-171.7 231.9-3.5 26.8-3.2 50.1.9 78.5 4.9 34.4 15.6 67.9 30.7 96.5 4.9 9.2 15.8 27 16.5 27 .2 0 .3-41.5.3-92.2 0-100.8-.1-99.2 6.1-126.2 24.6-107.3 118-185.6 227.9-191.1 10.4-.6 12.5-.4 15.5 1.2 4.9 2.5 8.3 9.4 7.6 15.1-1.3 9.8-5.4 12.2-23.1 13.3-96.7 6.4-176 72.6-198.9 166.2-5.1 20.8-5 16.7-5.3 134.7-.2 62.4 0 120.5.6 133.5.5 12.6.437 24.425.237 24.625-.2.2-7.1-6.5-15-14z"
    />
  </svg>
);

export default Logo;
